<script setup lang="ts">
  import { ref, provide, onMounted  } from 'vue'
  import { IonPage, IonHeader, IonToolbar, IonContent, onIonViewWillEnter } from '@ionic/vue';
  import SubRouter from "@/components/SubRouter.vue";
  import RewardNotifications from "@/components/RewardNotifications.vue";
  import { isApp } from '@/helpers/utils.js'
  const key = ref(0)
  const reward_notifications = ref("reward_notifications")
  provide('reward_notifications', reward_notifications)
  const first = ref(true)
  onIonViewWillEnter(async () => {
    // if we don't gate the first call, the sub-router will be re-created and we will end up mounting every component twice
    if (first.value) {
      first.value = false
      return
    }
    key.value += 1
  })
  // Create a ref to hold the scroll handler function
  const scrollHandler = ref(null)

  // This will be called by ion-content
  const handleScroll = (event) => {
    // Call the handler if one is registered
    if (scrollHandler.value) {
      scrollHandler.value(event.detail)
    }
  }
  // Provide a way for nested components to register their handler
  provide('registerScrollHandler', (fn) => {
    scrollHandler.value = fn
  })
</script>

<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true" @ionScroll="handleScroll">
			<!-- this needs to be here to block things behind the time etc on phones -->
      <ion-header collapse="condense" v-if="isApp">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>
      <reward-notifications ref="reward_notifications">
      </reward-notifications>
      <sub-router name="sub" :key="key" />
    </ion-content>
  </ion-page>
</template>
