<script setup>
import { ref, onMounted, computed, inject } from 'vue'
import { fetchWrapper } from '@/helpers/fetch-wrapper'
const email = ref('')
const terms = ref(false)
const firstName = ref('')
const lastName = ref('')
const inviteCode = ref('')
const nativeLanguage = ref('')
const learnLanguage = ref('')
const learnLevel = ref('')
const emailErrors = ref([])
const languageOptions = ref([])
const languageOptionsEnglish = ref([])
const emailDone = ref(false)
const paymentSuccessful = ref(false)
const paymentError = ref(false)
// this is to put high popularity languages at the top of the list, but we fill in all the others later
const languages = ref(['en', 'es', 'fr', 'ja', 'pl', 'zh', 'it', 'ko'])

import { useAuthStore } from '@/stores/auth.store'
import { useI18n } from 'vue-i18n'
import { storeToRefs} from 'pinia'
import { loadLocaleMessages } from '../i18n'
const isLoading = ref(true)
const { supportedLanguages, flags} = storeToRefs(useAuthStore())
const { t, locale } = useI18n()
const i18n = inject('i18n')
import { IonContent, IonPage } from '@ionic/vue'
import { isApp, isIosApp, isAndroidApp, getLanguageIdByName, capitalizeFirstLetter } from '@/helpers/utils.js'
import ProcessorApple from '@/components/ProcessorApple.vue'
import ProcessorAndroid from '@/components/ProcessorAndroid.vue'
import { getNode } from '@formkit/core'
import { useLoading } from 'vue-loading-overlay'
import { useRouter, useRoute } from 'vue-router'
import UserInterests from '@/components/UserInterests.vue'

// import supportedLanguages from "../locales/languages.json" as wtfLanguages
// import flags from '../locales/flags.json'

const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()

const loginDisplay = ref('none')
const attemptAppleBilling = ref(false)
const interestsLoaded = ref(false)


const profile = ref({})

const $loading = useLoading({})
const hidden = ref(true)
if (!isApp) {
  hidden.value = false
} else {
  hidden.value = true
}
const loadingContainer = ref(null)
for (const key in supportedLanguages.value) {
  // if supportedLanguages[key] is already in the languages array, don't add it again
  if (languages.value.includes(supportedLanguages.value[key])) {
    continue
  }
  languages.value.push(supportedLanguages.value[key])
}

/* this is called (and quite often fails due to user not being logged in) in order to skip the 
email step but continue registration if it was incomplete */
async function load_profile() {
  try {
    const response = await fetchWrapper.get(`/profile/`)
    console.log('load_profile', JSON.stringify(response))
    profile.value = response
  } catch (error) {
    console.log('error', error)
  }
}

/* hit the dashboard api (which guarantees there is a curriculum selected) and then go right to the first topic games
*/
async function startLearning() {
  console.log("startLearning")
  try {
    const response = await fetchWrapper.get(`/profile/dashboard`)
    const topic = response.curriculum.topics[0]
    console.log('startLearning', JSON.stringify(response))
    authStore.returnUrl = { name: 'GameView', params: { topicID: topic.id }}
    //router.push({ name: 'GameView', params: { topicID: topic.id }})
  } catch (error) {
    console.log('error', error)
  }
  submitHandler()
}

onMounted(async () => {
  /* make sure the register form is hidden until we know if they were already logged in.
    - if already logged in, redirect to dashboard
    - if not logged in, proceed to regular registration form and display it
  */
  authStore.returnUrl = '/dashboard'
  if (isApp) {
    const loader = $loading.show({
      fullPage: false,
      canCancel: false,
      container: loadingContainer.value,
      loader: "dots",
      opacity: "0.1"
    });
    console.log('before await')
    await new Promise(resolve => setTimeout(resolve, 3000))
    console.log('after await')
      // if we are already logged in, redirect to the dashboard
    if (authStore.user && authStore.details && authStore.details.registration_complete) {
      console.log('register form found already completed registration, sending to dashboard')
      loginDisplay.value = 'inline'
      router.push('/dashboard').then( failure => {
        console.log(failure)
        hidden.value = false
        loginDisplay.value = 'inline'
        loader.hide()
      })
    } else {
      await setupRegisterForm()
      hidden.value = false
      loginDisplay.value = 'inline'
      loader.hide()
    }
  } else {
      await setupRegisterForm()
      loginDisplay.value = 'block'
      hidden.value = false
      if (route.query.page) {
        var page = Number(route.query.page)
        const node = getNode('multi-step')
        if (page > 1) {
          console.log('autoregister page is greater than 1')
          email.value = `debug${Date.now()}@lingo.ing`
          terms.value = true
        }
        if (page > 2) {
          console.log('autoregister page is greater than 2')
          nativeLanguage.value = 'English'
          learnLanguage.value = 'Spanish'
          learnLevel.value = 'Beginner'
        }
        if (page > 3) {
          console.log('autoregister page is greater than 3')
          firstName.value = 'John'
          lastName.value = 'Doe'
        }
        if (page > 4) {
          console.log('autoregister page is greater than 4')
        }
        if (page > 1) {
          console.log('autoregister goto talkdetails')
          setTimeout(() => {
            console.log('autoregister actually go')
            node.goTo('talkDetails')
          }, 300)
        } 
        if (page > 2) {
          console.log('autoregister goto referral')
          setTimeout(() => {
            console.log('autoregister actually go')
            node.goTo('referral')
          }, 600)
        }
        if (page > 3) {
          console.log('autoregister goto interests')
          setTimeout(() => {
            console.log('actually go interests')
            interestsLoaded.value = true
            node.goTo('interests')
          }, 900)
        }
        if (page > 4) {
          console.log('autoregister goto complete')
          setTimeout(() => {
            console.log('actually go complete')
            node.goTo('complete')
          }, 1200)
        }
      }
  }
})

async function setupRegisterForm() {
  console.log('register.form after auth check, load profile')
  await load_profile()
  if (profile.value.email) {
    email.value = profile.value.email
    terms.value = true
    if (profile.value.skip_billing_prompt === true) {
      attemptAppleBilling.value = false
    } else {
      attemptAppleBilling.value = true
    }
    console.log('setupRegisterForm set attemptAppleBilling to', attemptAppleBilling.value)
    setTimeout(() => {
      console.log('register.form look for multi-step')
      const node = getNode('multi-step')
      node.next()
    }, 100)
  }
  // iterate supportedLanguages and create an array of objects for the select options.  Look up the "label" value from the flags object
  for (const [key, value] of Object.entries(supportedLanguages.value)) {
    languageOptions.value.push({ value: key, label: flags.value[value].display })
    languageOptionsEnglish.value.push({ value: key, label: capitalizeFirstLetter(t(`language.name.${getLanguageIdByName(key)}`)) + " (" + flags.value[value].display + ")"})
    // sort LanguageOptionsEnglish by the label value
    languageOptionsEnglish.value.sort((a, b) => (a.label > b.label) ? 1 : -1)
  }
  // populate the nativeLanguage value from local storage if it exists
  if (localStorage.getItem('language')) {
    for (const key in supportedLanguages.value) {
      if (supportedLanguages.value[key] === localStorage.getItem('language')) {
        nativeLanguage.value = key
      }
    }
  }
  isLoading.value = false
}

// computed function that lists all langauges except the native language
const getLearnLanguages = computed(() => {
  const learnLanguages = languageOptionsEnglish.value.filter((language) => language.value !== nativeLanguage.value)
  return learnLanguages
})

/* 
  onSetNativeLanguage is called when the native language is set.
  This exists so that we can prevent people from trying to learn their
  own native language.
  @param {string} newLanguage - the new language that was set, as a string eg "English"
  @param {object} node - the formkit input
*/
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function onSetNativeLanguage(newLanguage, node) {
  nativeLanguage.value = newLanguage
  if (nativeLanguage.value === learnLanguage.value) {
    learnLanguage.value = ""
  }
}

async function checkValidation (currentStep) {
  console.log('checkValidation', currentStep, currentStep.id)
  if (currentStep.id === 'talkDetails') {
    authStore.updateRegistration({nativeLanguage: nativeLanguage.value, learnLanguage: learnLanguage.value, learnLevel: learnLevel.value}, true) 
    return true
  }
  if (currentStep.id === 'referral') {
    authStore.updateRegistration({firstName: firstName.value, lastName: lastName.value}, true) 
    interestsLoaded.value = true
    return true
  }
  if (currentStep.id === 'interests') {
    return true
  }
  if (currentStep.id !== 'contactInformation') {
    return true
  }
  // if we are already logged in and we are at the email step, let us bypass
  if (profile.value.email && currentStep.id === 'contactInformation') {
    return true
  }
  if (emailDone.value === true) {
    return true
  }
  emailErrors.value = []
  const languageCode = localStorage.getItem('language')
  var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // authStore.register is called which creates the actual user on the server side.
  // authStore.user will be populated if this succeeds
  // also authStore.details will be set
  const result = await authStore.register(email.value, languageCode, timezone).catch( 
    function (error) { 
      console.log('registerError');
      console.log(error);
      emailErrors.value = [error]
    }
  ).then(() => {

    // Event snippet for Provided Email conversion page
    console.log("executing gtag")
    gtag('event', 'conversion', {
        'send_to': 'AW-16765928710/OvIuCIWAzOYZEIaKz7o-',
        'value': 1.0,
        'currency': 'CAD'
    });

    console.log('authStore.details', JSON.stringify(authStore.details))
    if (isIosApp) {
      console.log('setupRegisterForm', JSON.stringify(authStore.user), JSON.stringify(authStore.details))
      if (authStore.user && authStore.details && authStore.details.skip_billing_prompt === true) {
        attemptAppleBilling.value = false
      } else {
        attemptAppleBilling.value = true
      }
      console.log('checkValidation set attemptAppleBilling to', attemptAppleBilling.value)
    }
  })
  if (emailErrors.value.length > 0) {
    console.log('received error', result, 'blocking return')
    return false
  }
  emailDone.value = true
  return true
}

function submitHandler() {
  console.log('submitHandler')
  if (!authStore.returnUrl) {
    console.log('register submithandler called without a returnUrl, forcing dashboard')
    authStore.returnUrl = '/dashboard'
  }
  authStore.updateRegistration({firstName: firstName.value, lastName: lastName.value}) 
}


function checkDebugEmail () {
  // check if email.value is equal to debug, and if so, change its value to be debug + current time stamp @ lingo.ing
  if (email.value === 'debug') {
    email.value = `debug${Date.now()}@lingo.ing`
  }
}

async function checkInviteCode () {
  if (inviteCode.value === 'tester' || inviteCode.value === 'Tester') {
    paymentSuccessful.value = true
    const response = await fetchWrapper.post(`/tester/create-subscription/`, {})
    console.log(response)
  }
}

const recCompleted = (status) => {
  //  {"code":"1","errorMessage":"Purchase was cancelled."}
  // success
  if (isIosApp) {
    console.log('apple.billing returned a status', status)
  } else if (isAndroidApp) {
    console.log('android.billing returned a status', status)
  } else {
    console.log('unknown billing returned a status', status)
  }
  if (status === 'success') {
    // paid sale
    gtag('event', 'conversion', {
        'send_to': 'AW-16765928710/lqaiCP-p3uUZEIaKz7o-',
        'value': 1.0,
        'currency': 'CAD',
        'transaction_id': ''
    });
    paymentSuccessful.value = true
  } else {
    paymentError.value = status.errorMessage
  }
}
function getFlag (language) {
        // check for language to exist as a key in the flags dictionary, if it does, return the value
        if (flags.value[language]) {
                return flags.value[language].flag
        }
}
function languageDisplay (language) {
        // check for language to exist as a key in the flags dictionary, if it does, return the value
        if (flags.value[language]) {
					return flags.value[language].display
        }
}

async function updateLanguage (language) {
        await loadLocaleMessages(i18n, language)
        locale.value = language
        localStorage.setItem('language', language)
        config.locale = language
  // probably safest to force this in the login form as the login form needs its config etc
  location.reload()
}

</script>

<template>
  <ion-page>
    <ion-content id="loginview">
      <div ref="loadingContainer" id="loadingContainer" />
      <site-header v-if="isApp === false" :needsReload="true" />
        <section v-if="hidden === false" class="loginForm my-5 py-4" :style="{display: loginDisplay}">
        <div v-if="!isLoading" class="container">
          <div class="row justify-content-center my-5">
            <div class="col-xl-6 col-sm-8">
              <h1 class="h1-title text-center">{{ t('register.lets-get-started') }}</h1>
            </div>
          </div>
          <div class="row justify-content-center mt-4">
            <div class="col-xl-6 col-sm-8 align-self-center justify-content-center">
              <FormKit :actions="false" type="form" @submit="submitHandler" form-class="outer-container justify-content-center" outer-classes="p-0">
                <FormKit form-class="multi-container" id="multi-step" type="multi-step" tab-style="progress" :allow-incomplete="false" :before-step-change="({ currentStep, targetStep, delta }) => { return checkValidation(currentStep) }" outer-class="row justify-content-center">
                  <FormKit type="step" id="contactInformation" name="contactInformation" :label="t('register.contact-information')">
                    <!-- collect name, email, and company info -->
                    <FormKit 
                      name="email"
                      type="email" 
                      :placeholder="t('register.please-enter-your-email')" 
                      v-model="email"
                      :errors="emailErrors"
                      validation="required|email" 
                      @change="checkDebugEmail()"
                    />
                    <div class="registerEmailConsent mt-4 mb-5">
                      <FormKit
                        type="checkbox"
                        help="placeholder"
                        name="terms"
                        v-model="terms"
                        validation="accepted"
                        validation-visibility="dirty"
                      >
                        <template #help>
                          {{ t('register.i-agree-to-the') }} <a href="https://www.lingo.ing/terms-of-use" target="_new">{{ t('navbar.terms-of-use') }}</a> {{ t('register.and') }} <a target=_new href="https://www.lingo.ing/privacy-policy"> {{ t('navbar.privacy-policy') }} </a>
                        </template>
                      </FormKit>
                    </div>
                  </FormKit>
                  <FormKit type="step" id="talkDetails" name="talkDetails" :label="t('register.language-details')">
                  <h4 class="fnt-weight-600 mb-4">{{ t("register.tell-us-about-the-language-youd-like-to") }}</h4>
                    <p class="validationMessageBox">{{ t('register.we-have-sent-you-a-validation-email-to') }} </p>
                      <FormKit 
                        name="nativeLanguage"
                        v-model="nativeLanguage"
                        type="select" 
                        :label="t('register.your-native-language')" 
                        :placeholder="t('register.select-your-native-language')"
                        :help="t('register.this-is-the-language-you-will-be-learning')"
                        validation="required"
                        @input="onSetNativeLanguage"
                        :options=languageOptions
                      />
                      <FormKit 
                        name="learnLanguage"
                        v-model="learnLanguage"
                        type="select" 
                        :label="t('register.i-would-like-to-learn')"
                        :placeholder="t('register.select-a-language-to-learn')"
                        :help="t('register.what-language-would-you-like-to-learn-first')"
                        :options=getLearnLanguages
                        validation="required"
                      />
                      <FormKit 
                        name="teachLevel"
                        v-model="learnLevel"
                        type="select" 
                        :label="t('register.my-current-level-is')"
                        :placeholder="t('register.select-your-current-level')"
                        :help="t('register.what-is-your-current-skill-level-in-your')"
                        :options="[
                           { value: 'Beginner', label: t('profile.beginner') },
                           { value: 'Intermediate', label: t('profile.intermediate') },
                           { value: 'Advanced', label: t('profile.advanced') }
                        ]"
                        validation="required"
                      />
                  </FormKit>
                  <FormKit type="step" id="referral" name="referral" :label="t('register.your-details')">
                    <h4 class="fnt-weight-600 mb-4">{{ t('register.please-tell-us-a-bit-about-yourself') }}</h4>
                    <FormKit 
                      v-model="firstName"
                      type="text" 
                      :placeholder="t('register.your-first-name')" 
                      validation="required"
                    />
                    <FormKit 
                      v-model="lastName"
                      type="text" 
                      :placeholder="t('register.your-last-name')" 
                      validation="required"
                    />
                    <FormKit v-if="!isIosApp"
                      v-model="inviteCode"
                      type="text" 
                      :placeholder="t('register.your-invite-code---if-you-received-one-')" 
                      :help="t('register.this-field-is-optional-')"
                      @blur=checkInviteCode()
                    />
                  </FormKit>
                  <FormKit type="step" id="interests" name="interests" :label="t('register.your-interests')">
                    <UserInterests v-if="interestsLoaded" />
                      <!--
                    <template v-if="isIosApp === false && isAndroidApp === false" #stepNext>
                      <div class="formkit-step-next">
                        <FormKit class="formkit-step-next" type="submit" />
                      </div>
                    </template>
                      -->
                  </FormKit>
                  <!-- in the iOS app we display a payment step -->
                  <!--
                  <FormKit v-if="isIosApp && attemptAppleBilling === true" type="step" id="payment" name="payment" :label="t('register.select-plan')">
                    <h4 class="fnt-weight-600 mb-4">{{ t('register.please-select-your-plan') }}</h4>
                    <div v-if="paymentError" class="alert alert-danger" role="alert">
                        {{ paymentError }}
                    </div>
                    <processor-apple v-if="paymentSuccessful === false" @sendCompleted="recCompleted" sendCompletion="true" mode="registration" />
                    <FormKit 
                      v-model="paymentSuccessful"
                      type="hidden" 
                      :placeholder="t('register.fake')" 
                      validation="required"
                    />
                    <div v-if="paymentSuccessful">
                      <div class="alert alert-success">
                        <b>{{ t('register.thank-you-for-subscribing') }}</b><br />{{ t('register.you-will-not-be-charged-until-the-end') }}
                      </div>
                    </div>
                    <template v-if="paymentSuccessful" #stepNext>
                      <div class="formkit-step-next">
                        <FormKit class="formkit-step-next" type="submit" />
                      </div>
                    </template>
                  </FormKit>
                  <FormKit v-else-if="isIosApp && attemptAppleBilling === false" type="step" id="payment-android" name="payment-android" :label="t('register.select-plan')">
                    <div class="row pricing-plans-small row-gap-4 justify-content-center my-4">
                      <div class="col-lg-6">
                        <div class="plan pb-2">
                          <div class="plan-head">
                            <h3 class="h3-title-lg fnt-weight-700 mb-3">{{ t('register.welcome-to-lingo-ing') }}</h3>
                          </div>
                          <div class="plan-body my-3">
                            <ul class="p-0 py-1">
                              <li class="alert alert-success mb-1">{{ t('register.lets-get-started-on-your-journey') }}</li>
                              <li>{{ t('home.unlimited-access') }}</li>
                              <li>{{ t('home.unlimited-practice-sessions') }}</li>
                              <li>{{ t('home.immersive-roleplays-&-modes') }}</li>
                              <li>{{ t('home.personalized-lessons') }}</li>
                              <li>{{ t('home.personalized-learning') }}</li>
                              <li>{{ t('home.advanced-voices') }}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <template #stepNext>
                      <div class="formkit-step-next">
                        <FormKit class="formkit-step-next" type="submit" />
                      </div>
                    </template>
                  </FormKit>
                  <FormKit v-if="isAndroidApp" type="step" id="payment-android" name="payment-android" :label="t('register.select-plan')">
                    <h4 class="fnt-weight-600 mb-4">{{ t('register.please-select-your-plan') }}</h4>
                    <div v-if="paymentError" class="alert alert-danger" role="alert">
                        {{ paymentError }}
                    </div>
                    <processor-android v-if="paymentSuccessful === false" @sendCompleted="recCompleted" sendCompletion="true" mode="registration" />
                    <FormKit 
                      v-model="paymentSuccessful"
                      type="hidden" 
                      :placeholder="t('register.fake')" 
                      validation="required"
                    />
                    <div v-if="paymentSuccessful">
                      <div class="alert alert-success">
                        <b>{{ t('register.thank-you-for-subscribing') }}</b>{{ t('register.you-will-not-be-charged-until-the-end') }}
                      </div>
                    </div>
                    <template v-if="paymentSuccessful" #stepNext>
                      <div class="formkit-step-next">
                        <FormKit class="formkit-step-next" type="submit" />
                      </div>
                    </template>
                  </FormKit>
                  -->
                  <FormKit type="step" id="complete" name="complete" :label="t('register.complete')">
                    <div class="alert alert-success" role="alert">
                      <b>{{ t('register.thank-you-for-signing-up') }}</b>
                      <br/>
                      <br/>
                      {{ t('register.now-choose-start-learning-right-away') }}
                      <div class="btn btn-primary btn-primary-cs mt-2 mb-2" @click="router.push('/dashboard')">{{ t('register.explore') }}</div>
                      <div class="btn btn-primary btn-primary-cs" @click="startLearning()">{{ t('register.start-learning') }}</div>
                    </div>
                  </FormKit>
                </FormKit>
              </FormKit>
            </div>
            <div class="loginForm-signup-link mt-5 pt-5">
              <p>{{ t('register.already-a-member') }}&nbsp;
                <router-link v-if="isApp" to="/login"> {{ t('register.log-in-here') }}!</router-link>
                <router-link v-else to="/login"> {{ t('register.log-in-here') }}!</router-link>
              </p>
            </div>
          </div>
                  <div v-if="isApp">
                    <CDropdown toggerText="Language" variant="nav-item" direction="dropup">
                      <CDropdownToggle togglerText="Dropdown">
                        <CButton>
                          <country-flag v-if="getFlag(locale)" :country="getFlag(locale)" size="normal" />
                          {{ languageDisplay(locale) }}
                        </CButton>
                      </CDropdownToggle>
                      <CDropdownMenu class="overflow-auto">
                        <CDropdownItem  v-for="language in languages" :key="language" @click="updateLanguage(language)">
                          <CDropdownDivider />
                          <country-flag v-if="getFlag(language)" :country="getFlag(language)" size="normal" />
                          {{ languageDisplay(language) }}
                        </CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
        </div>
      </section>
      <div class="cityBgBottom"></div>
      <site-footer v-if="isApp === false" />
    </ion-content>
  </ion-page>
</template>
<style scoped>
  ion-content {
    --background: unset !important;
    background-image: url('@/assets/images/lingoing-bg-icons.png');
    background-size: 100%;
    background-repeat: repeat;
  }

.formkit-outer {
  margin: 0 auto 1em auto;
}
.input-group {
  display: flex;
  gap: 1em;
}
.formkit-outer[data-type="multi-step"] > .formkit-wrapper {
  margin: 0 auto 1em auto;
}
.formkit-actions > .formkit-outer > div.formkit-wrapper {
  margin: 0 auto 1em auto;
}

#validationDiv .formkit-outer > .formkit-wrapper {
  margin: 0 auto 1em auto;
}
.formkit-outer >>> .formkit-tab-label {
  color: black;
}
div >>> .formkit-tab-label {
  font-size: 0.75em !important;
}
* >>> .vl-overlay {
     background-image: url('@/assets/images/appLoading.png') !important;
     background-size: 100%;
     repeat: no-repeat;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
* >>> div.dropdown-menu {
  height: 45vh !important;
  min-width: 200px !important;
}
</style>
