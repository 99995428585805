<template>
    <div @click="go_to()" class="card carousel-card mb-2 m-auto " @mouseover="hover = true" @mouseleave="hover = false" :class="{ 'hover-card': hover }" >
        <div class="card-body rounded-3"   :style="{ backgroundImage: 'url(' + topic.background_image + ')' }" >
            <h5 class="card-title">{{ t(`curriculum-topic.name.${topic.id}`) }}</h5>
            <div v-if="topic.words_total > 0" class="progress mt-2" style="position: absolute; bottom: 10px; width: 90%; height: 20px;">
                <div class="progress-bar" role="progressbar"
                    :style="{ width: (topic.words_learned / topic.words_total * 100) + '%' }" aria-valuenow="25"
                    aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <small v-if="topic.words_total > 0" class="text-muted" style="position: absolute; bottom: 10px; left: 10px; width: 90%;">{{
                topic.words_learned }}/{{ topic.words_total }} {{ t('curriculum.words') }}</small>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const router = useRouter()

const props = defineProps({
  topic: {
    type: Object,
    required: true
  },
  to: {
    type: Object,
    required: false
  }
})

function go_to() {
  if (props.to) {
    router.push(props.to)
  } else {
    router.push({ name: 'CurriculumTopicView', params: { topicID: props.topic.id } })
  }
}

const hover = ref(false)
</script>
<style lang="css" scoped>

h1,
h2 {
  font-weight: normal;
}
.progress-bar {
    background: #aaf;
}
.carousel__slide {
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.carousel-card,
.card-body {
  height: calc(115px * 0.94);
  width: calc(200px * 0.94);
  background-size: cover;
  background-position: center;
  position: relative;
}

/* Media query for larger screens */
@media (min-width: 375) {
  .carousel-card,
  .card-body {
    height: 115px;
    width: 200px;
  }
}

h5.card-title {
  font-size: 100%;
  color: white !important; /* Text color */
  padding: 5px; /* Padding around the text */
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 1); /* Stronger text shadow */
  width: 100%;
  text-align: center;
  -webkit-text-stroke: 3px black; /* Outline for the text */
  paint-order: stroke fill;
}

.hover-card {
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ddf;
}
</style>