<script setup>
  import { ref, onMounted } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import { fetchWrapper } from '@/helpers/fetch-wrapper'
  import { useI18n } from 'vue-i18n'
  import chardCardPic from '@/assets/images/dashboard-chat.webp'
  import vocabularyCardPic from '@/assets/images/vocabulary-card.webp'
  import flashCardsPic from '@/assets/images/flashcards.png'
  import studyPicCard from '@/assets/images/study-pic.webp'
  import readingTogetherPic from '@/assets/images/dashboard-reading-together.webp'
  import trophyPic from '@/assets/images/trophy.webp'
  import { standardizeTime, capitalizeFirstLetter, markAutoProductDisplayComplete, isIosApp, isAndroidApp } from '@/helpers/utils'
  import DashboardPanelVocabSlide from '@/components/DashboardPanelVocabSlide.vue'
  import DashboardPanelChatSlide from '@/components/DashboardPanelChatSlide.vue'
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide, Navigation } from 'vue3-carousel'
  import { useRouter } from 'vue-router'
  import { env } from '@/helpers/env.js'
  import DashboardPanel from '@/components/DashboardPanel.vue'
  import DailyChallengePanel from '@/components/DailyChallengePanel.vue'
  import { isApp } from '@/helpers/utils.js'
  const router = useRouter()
  const { t } = useI18n()
  const authStore = ref(false)
  const isLoading = ref(true)
  authStore.value = useAuthStore()
  const profile = ref({})
  const topContent = ref(false)
  const showBillingWarning = ref(false)
  const showBillingLock = ref(false)
  const dailyChallenges = ref([])
  const dashboard = ref({})
  const ready = ref(false)

  const carouselConfig = {
    itemsToShow: "auto",
    wrapAround: false,
    mouseDrag: true,
    autoplay: 0,
    gap: 1,
    snapAlign: "start",
  }

  onMounted(async () => {
    await loadData()
  })

  async function loadData() {
    isLoading.value = true
    var platform = ''
    if (isIosApp) {
      platform = 'ios'
    } else if (isAndroidApp) {
      platform = 'android'
    } else {
      platform = 'web'
    }
    const response = await fetchWrapper.get(`/profile/?release_version=${env.VUE_APP_RELEASE_VERSION}&platform=${platform}`)
    console.log(response)
    profile.value = response
    dailyChallenges.value = response.daily_challenges
    showBillingWarning.value = profile.value.billing_warning
    showBillingLock.value = profile.value.billing_locked
    console.log('dashboard check', authStore.value)
    if (!authStore.value.details.registration_complete) {
      console.log('dashboard registration check failed, sending to register')
      if (isApp) {
        router.push('/')
      } else {
        router.push('/register')
      }
      return
    }
    const dashboardResult = await fetchWrapper.get('/profile/dashboard/')
    dashboard.value = dashboardResult
    console.log('dashboard', dashboard.value)
    isLoading.value = false
  }
</script>

<template>
  <nice-header />
  <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
    <CModal :visible="showBillingLock" @close="() => { router.push('/billing')}"> 
		<CModalHeader>
			<CModalTitle>{{ t('billing.account-unavailable---billing-issue') }}</CModalTitle>
		</CModalHeader>
		<CModalBody>
      <p>{{ t('billing.we-are-sorry-but-your-account-is-currently') }}</p>
		</CModalBody>
	</CModal>
  <section class="my-0" ref="topContent">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="bg-white border-0 rounded border-light p-1 p-sm-2 p-md-5 w-100 fmxw-500">
            <div class="text-center text-md-center mb-1 mt-md-0 col-12">
              <h5 v-if="profile.first_name">{{ t("dashboard.welcome-back-firstname", { first_name: capitalizeFirstLetter(profile.first_name) }) }}</h5>
            </div>
            <CAlert color="primary" v-if="isIosApp && profile.retired_ios">
              {{ t('profile.there-is-a-newer-version-of-lingo-ing-available') }}
              <br><br>
              <b><a href="https://apps.apple.com/ca/app/lingo-ing-ai-language-tutor/id6581480179">{{ t('profile.please-upgrade-here') }}</a></b>
            </CAlert>
            <CAlert color="warning" v-if="profile.pending_validation">{{ t("dashboard.we-have-sent-a-validation-email-to-you", {email: profile.email}) }}</CAlert>
            <CAlert color="warning" v-if="showBillingWarning && !showBillingLock">
            <b>{{ t("billing.billing-issue") }}</b><br />
              {{ t("billing.we-seem-to-be-having-an-issue-with") }}<br /><router-link to="/billing">{{ t('billing.billing-area') }}</router-link><br>
            </CAlert>
            <div class="d-flex gap-2"></div>
            <div class="row" >
              <div class="col-md-12 mt-3">
                <DashboardPanel to="/curriculum/progress" :image="flashCardsPic" :disableButton="true" :showCarousel="true"
                v-if="dashboard.curriculum">
                  <template v-slot:title>{{ t('dashboard.study') }}</template>
                  <template v-slot:description>{{ t('dashboard.learn-new-words-and-phrases-for-various-scenarios') }}</template>


                  <template v-slot:description_short>{{ t('dashboard.learn-new-words-and-phrases-for-various-scenarios') }}</template>
                  <template #call-to-action>
                    {{ t('dashboard.learn') }}
                  </template>
                  <template #slides>
                    <Carousel v-if="dashboard.curriculum && dashboard.curriculum.topics" v-bind="carouselConfig" style="padding:5px 10px">
                      <Slide v-for="(topic, index) in dashboard.curriculum.topics" :key="index">
                        <DashboardPanelVocabSlide :topic="topic" />
                      </Slide>
                      <template #addons>
                        <Navigation />
                      </template>
                    </Carousel>
                  </template>
                </DashboardPanel>
                <DashboardPanel to="/chat" :image="chardCardPic" :disableButton="true" :showCarousel="true"
                >
                  <template v-slot:title>{{ t('dashboard.chat') }}</template>
                  <template v-slot:description>{{ t('dashboard.chat-with-an-ai--explore-role-playing') }}</template>
                  <template v-slot:description_short>{{ t('dashboard.boost-your-language-skills-with-our-exciting-chat').split('.')[0] }}</template>
                  <template #slides>
                    <Carousel v-if="dashboard.chat && dashboard.chat" v-bind="carouselConfig" style="padding: 5px 10px">
                      <Slide v-for="(chat_template, index) in dashboard.chat" :key="index" class="rounded-3">
                        <DashboardPanelChatSlide :chat_template="chat_template" />
                      </Slide>
                      <template #addons>
                        <Navigation />
                      </template>
                    </Carousel>
                  </template>
                </DashboardPanel>
                <DashboardPanel to="/article/list/" :image="readingTogetherPic" :disableButton="true">
                  <template v-slot:title>{{ t('dashboard.reading-together') }}</template>
                  <template v-slot:description>{{ t('dashboard.reading-together-description') }}</template>
                  <template v-slot:description_short>{{ t('dashboard.reading-together-description').split('.')[0] }}</template>
                  <template v-slot:details>
                  </template>
                  <template #call-to-action>
                    {{ t('dashboard.read-articles') }}
                  </template>
                </DashboardPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <nice-footer />
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
.carousel__slide {
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.carousel-card {
  height: 115px;
  width: 200px;
  background-size: cover;
}

.carousel.card-body {
  height: 115px;
  width: 200px;
}

h5.card-title {
  font-size: 100%;
}
</style>


