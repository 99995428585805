<template>
    <div @click="go_to()" class="card carousel-card" @mouseover="hover = true" @mouseleave="hover = false" :class="{ 'hover-card': hover, 'regular-card': !hover }" :style="{ backgroundImage: `url(${chat_template.background_image})` }">
        <div class="card-body">
            <h5 class="card-title">{{ t(`chat-template.title.${chat_template.id}`) }}</h5>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { defineProps } from 'vue'
import { fetchWrapper } from '@/helpers/fetch-wrapper'

import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const router = useRouter()

const props = defineProps({
  chat_template: {
    type: Object,
    required: true
  }
})

async function go_to() {
  let chat_template = props.chat_template
  await go_to_setup(chat_template.id)
}
  async function go_to_setup(templateID) {
    const response = await fetchWrapper.post(`/chat/template/`, {templateID: templateID})
    if (response.variants.length === 0) {
      router.push({
        name: 'ChatInterfaceTemplateID',
        params: {
          templateID: templateID
        }
      })
    }
    else {
      router.push({
        name: 'ChatSetupTemplateID',
        params: {templateID: templateID}
      })
    }
  }

const hover = ref(false)
</script>
<style lang="css" scoped>

h1,
h2 {
  font-weight: normal;
}
.progress-bar {
    background: #aaf;
}
.carousel__slide {
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.carousel-card {
  height: 115px;
  width: 200px;
  background-size: cover;
  background-position: center;
}

.carousel.card-body {
  height: 115px;
  width: 200px;
}

h5.card-title {
  font-size: 100%;
  color: white !important; /* Text color */
  padding: 5px; /* Padding around the text */
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 1); /* Stronger text shadow */
  width: 100%;
  text-align: center;
  -webkit-text-stroke: 3px black; /* Outline for the text */
  paint-order: stroke fill;
}

.hover-card {
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ddf;
}
.regular-card {
  background-color: #f8f8ff;
}
</style>