<!-- UserInterests.vue -->
<script setup>
import { ref, computed, onMounted } from 'vue'
import { fetchWrapper } from '@/helpers/fetch-wrapper'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const keywords = ref([])
const loading = ref(true)
const error = ref(null)
const saving = ref(false)
const saveError = ref(null)
const saveSuccess = ref(false)

const selectedCount = computed(() => {
  return keywords.value.filter(k => k.is_selected).length
})

const fetchKeywords = async () => {
  try {
    loading.value = true
    error.value = null
    const response = await fetchWrapper.get('/curriculum/keywords/')
    console.log('response was', response)
    keywords.value = response
  } catch (err) {
    error.value = 'Failed to load interests. Please try again.'
    console.error('Error fetching keywords:', err)
  } finally {
    loading.value = false
  }
}

const toggleInterest = async (keyword) => {
  keyword.is_selected = !keyword.is_selected
  saveSuccess.value = false
  await saveInterests()
}

const saveInterests = async () => {
  try {
    saving.value = true
    saveError.value = null
    saveSuccess.value = false
    
    const selectedIds = keywords.value
      .filter(k => k.is_selected)
      .map(k => k.id)
      
    await fetchWrapper.post('/curriculum/keywords/update_interests/', {
      keyword_ids: selectedIds
    })
    
    saveSuccess.value = true
  } catch (err) {
    saveError.value = 'Failed to save interests. Please try again.'
    console.error('Error saving interests:', err)
  } finally {
    saving.value = false
  }
}

// Fetch keywords when component is mounted
onMounted(() => {
  console.log('user interests mounted, fetch keywords')
  fetchKeywords()
})
</script>

<template>
  <div class="container">
    <div class="user-interests">
      <h2 class="text-2xl font-bold mb-4">{{ t('curriculum.select-your-interests') }}</h2>
      
      <div v-if="loading" class="text-gray-600">
        {{ t('curriculum.loading-interests---') }}
      </div>
      
      <div v-else-if="error" class="text-red-600">
        {{ error }}
      </div>
      
      <div v-else>
        <div class="d-flex flex-wrap gap-2 mb-6">
          <div
            v-for="keyword in keywords"
            :key="keyword.id"
            class="card flex-grow-1"
            :class="keyword.is_selected ? 'bg-primary-cs text-white' : 'bg-light text-dark'"
            style="cursor: pointer;"
            @click="toggleInterest(keyword)"
          >
            <div class="card-body">
              <h5 class="card-title">{{ keyword.name }}</h5>
            </div>
          </div>
        </div>
        
        <div class="d-flex justify-content-between align-items-center">
          <p class="text-sm text-gray-600">
            {{ selectedCount }} {{ t('curriculum.interests-selected') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.text-white div h5 {
  color: white !important;
}
.card-body {
  padding: 0.1rem;
}

.flex-grow-1 {
  flex-grow: 1;
}
</style>