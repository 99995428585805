<template>
  <ion-app>
    <ion-split-pane contentId="main-content" when="false">
      <ion-menu contentId="main-content" type="overlay" swipe-gesture="false" side="start">
        <ion-content>
        <ion-list>
          <ion-list-header></ion-list-header>
          <ion-menu-toggle autoHide="false">
            <template v-if="authStore.user && authStore.details">
              <ion-item><router-link to="/dashboard/">{{ t('navbar.dashboard') }}</router-link></ion-item>
              <ion-item><router-link to="/curriculum/progress/">{{ t('navbar.flashcards') }}</router-link></ion-item>
              <ion-item><router-link to="/chat/">{{ t('navbar.chat-mode') }}</router-link></ion-item>
              <ion-item><router-link to="/chat/history/">{{ t('navbar.active-chats') }}</router-link></ion-item>
              <ion-item><router-link to="/vocab/">{{ t('navbar.vocab-list') }}</router-link></ion-item>
              <ion-item><router-link to="/profile/">{{ t('navbar.profile') }}</router-link></ion-item>
              <ion-item><router-link to="/billing/">{{ t('navbar.billing') }}</router-link></ion-item>
              <ion-item><router-link to="/docs/">{{ t('navbar.documentation') }}</router-link></ion-item>
              <ion-item><router-link to="/feedback/">{{ t('navbar.feedback') }}</router-link></ion-item>
              <ion-item><router-link @click=logout() to="/">{{ t('navbar.logout') }}</router-link></ion-item>
            </template>
            <template v-else>
              <ion-item><router-link to="/login">{{ t('navbar.login') }}</router-link></ion-item>
              <ion-item>
                <router-link v-if="isApp" to="/">{{ t('navbar.register') }}</router-link>
                <router-link v-else to="/register/">{{ t('navbar.register') }}</router-link>
              </ion-item>


            </template>
            <ion-item>
              <ion-label>Language
                <country-flag v-if="getFlag(locale)" :country="getFlag(locale)" size="normal" class="mr-2"/>
              </ion-label>
              <ion-select @ionChange="updateLanguage($event.detail.value)" :value="locale">
                <ion-select-option v-for="language in languages" :key="language" :value="language">
                  {{ languageDisplay(language) }}
                </ion-select-option>
              </ion-select> 
            </ion-item>
          </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content" animated="false" swipe-gesture="false"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script setup lang="ts">
  import { App, URLOpenListenerEvent } from '@capacitor/app'
  import { PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications';
  import { IonApp, IonRouterOutlet, IonSplitPane, IonItem, IonLabel, IonSelect, IonList, IonMenu, IonMenuToggle, IonContent, IonSelectOption, IonListHeader } from '@ionic/vue';
  import { useAuthStore } from '@/stores/auth.store'
  import { useI18n } from 'vue-i18n'
  import { ref, onMounted, defineProps, inject } from 'vue'
  import flags from '@/locales/flags.json'
  import { useRouter } from 'vue-router'
  const router = useRouter()
  const { t, locale } = useI18n()
	const authStore = ref(false)
  const languages = ref(['en', 'es', 'fr', 'ja', 'pl', 'zh', 'it', 'ko'])
  import { changeLocale } from '@formkit/vue'
  import supportedLanguages from "@/locales/languages.json"
  import { loadLocaleMessages } from './i18n'
  import { isIosApp, isAndroidApp, isApp } from './helpers/utils.js'
  const i18n = inject('i18n')
  authStore.value = useAuthStore()

  const props = defineProps({
    needsReload: {
      type: Boolean,
      default: false
    },
    contentId: {
      type: String,
      default: 'main-content'
    }
  })

  for (const key in supportedLanguages) {
    if (languages.value.includes(supportedLanguages[key])) {
      continue
    }
    languages.value.push(supportedLanguages[key])
  }

  onMounted(() => {
    if (localStorage.getItem('language')) {
      loadLocaleMessages(i18n, localStorage.getItem('language'))
      locale.value = localStorage.getItem('language')
      changeLocale(localStorage.getItem('language'))
    } else {
      const browserLanguage = (window.navigator.userLanguage || window.navigator.language)
      const [language, country] = browserLanguage.split('-')
      for (const key in supportedLanguages) {
        if (supportedLanguages[key] === language) {
          console.log('discarded', country, 'and using browser setting to set language to', language)
          loadLocaleMessages(i18n, language)
          localStorage.setItem('language', language)
          locale.value = language
          changeLocale(language)
        }
      }
    }
  })


  function logout () {
    authStore.value.logout()
  }

  function updateLanguage (language) {
    loadLocaleMessages(i18n, language)
    locale.value = language
    localStorage.setItem('language', language)
    changeLocale(language)
    // AE enable later config.locale = language
    if (props.needsReload === true) {
      location.reload()
    }
  }

  function getFlag (language) {
    // check for language to exist as a key in the flags dictionary, if it does, return the value
    if (flags[language]) {
      return flags[language].flag
    }
  }
  function languageDisplay (language) {
    // check for language to exist as a key in the flags dictionary, if it does, return the value
    if (flags[language]) {
      return flags[language].display
    }
  }
  async function setupPushNotifications() {
    console.log('push.notifications setupPushNotifications')
    // Request notification permission
    const permission = await PushNotifications.requestPermissions();
    console.log('push.notifcations permission', permission)
    
    if (permission.receive === 'granted') {
      // Register with APNs (Apple Push Notification service)
      console.log('push.notifications receive was granted, try register')
      await PushNotifications.register();
    } else {
      console.log('push.notifications permission not granted', permission)
    }
  }

  if (isIosApp) {
    App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
      console.log('appUrlOpen alert event', event)
      const slug = event.url.split('.lingo.ing').pop();
      console.log('appUrlOpen alert slug', slug)
      if (slug) {
        console.log('appUrlOpen slug will redirect to', slug)
        router.push(slug)
        console.log('appUrlOpen redirect done')
      }
    })
    // Handle incoming push notifications
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      // Handle the received notification here
      console.log('push.notifications Push received: ' + JSON.stringify(notification));
    })
    PushNotifications.addListener('registrationError', error => {
      console.log('push.notifications registrationError received: ' + error)
    })
    PushNotifications.addListener('registration', token => {
      console.log('push.notifications registration received: ', token.value)
      // if token does not exist in authStore.value.notificationIdentifiers, add it
      if (!authStore.value.notificationIdentifiers.includes(token.value)) {
        authStore.value.notificationIdentifiers.push(token.value)
      }
    })
    /* this is trigger for when a user clicks a notification and the app is backgrounded */
    PushNotifications.addListener('pushNotificationActionPerformed',
      (res: PushNotificationActionPerformed) => {
        console.log('pushNotificationActionPerformed', JSON.stringify(res))
        if (res.notification.data && res.notification.data.url && typeof(res.notification.data.url) === 'string') {
          // strip leading domain if we included it
          res.notification.data.url = res.notification.data.url.split('.lingo.ing').pop()
          console.log('pushNotificationActionPerformed url', res.notification.data.url)
          router.push(res.notification.data.url)
        }
    });

    setupPushNotifications();
  } else if (isAndroidApp) {
    console.log('identifierDebug in app.vue setup listeners')
    // for deep links; ie web links to open inside the app
    App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
      console.log('appUrlOpen alert event', event)
      const slug = event.url.split('.lingo.ing').pop();
      console.log('appUrlOpen alert slug', slug)
      if (slug) {
        console.log('appUrlOpen slug will redirect to', slug)
        router.push(slug)
        console.log('appUrlOpen redirect done')
      }
    })
    // Handle incoming push notifications
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      // Handle the received notification here
      console.log('push.notifications Push received: ' + JSON.stringify(notification));
    })
    PushNotifications.addListener('registrationError', error => {
      console.log('identifierDebug push.notifications registrationError received: ' + error)
    })
    PushNotifications.addListener('registration', token => {
      console.log('aa identifierDebug push.notifications registration received: ', token.value)
      // if token.value does not exist in authStore.value.notificationIdentifiers, add it
      if (!authStore.value.notificationIdentifiers.includes(token.value)) {
        authStore.value.notificationIdentifiers.push(token.value)
      }
    })
    console.log('identifierDebug in app.vue')
    setupPushNotifications();
  }


</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  @import 'styles/style.scss';
  @import 'styles/custom.scss';
  #app {
   font-family: "Poppins", Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: #2c3e50;
   // margin-top: 60px;
  }
</style>
