<script setup>
/*
usage:
<DashboardPanel>
  <template #title>
    {{ t('dashboard.daily-challenges') }}
  </template>
  <template #description>
    {{ t('dashboard.daily-challenges-description') }}
  </template>
  <template #description_short>
    {{ t('dashboard.daily-challenges-description-short') }}
  </template>
  <template #details>
    {{ t('dashboard.daily-challenges-details') }}
  </template>
  <template #call-to-action>
    {{ t('dashboard.start-now') }}
  </template>
  <template #slide-0>
    <img src="@/assets/images/dashboard-chat.webp" alt="chat" />
  </template>
  <template #slide-1>
    <img src="@/assets/images/dashboard-chat.webp" alt="chat" />
  </template>
  <template #slide-2>
    <img src="@/assets/images/dashboard-chat.webp" alt="chat" />
  </template>
</DashboardPanel>
*/
import { ref, defineProps, onMounted, computed, useSlots } from 'vue'
import { useRouter } from 'vue-router'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation, SNAP_ALIGN_OPTIONS } from 'vue3-carousel'
const router = useRouter()
const props = defineProps({
  to: String,
  image: String,
  showCarousel: Boolean,
  disableButton: Boolean
})
const hover = ref(false)
const carouselReady = ref(false)  // for unknown reasons, if we show the carousel immediately it breaks *everything*
const carouselConfig = {
  itemsToShow: "auto",
  wrapAround: false,
  mouseDrag: true,
  autoplay: false,
  snapAlign: "start",
}
import { useI18n } from 'vue-i18n'
import { CCardBody } from '@coreui/vue-pro'
const { t } = useI18n()
const show_details = ref(true)
const slots = useSlots()

function go_to() {
  router.push(props.to)
}

onMounted(() => {
  if (props.showCarousel) {
    setTimeout(() => {
      carouselReady.value = true
    }, 100)
  }
})

const slides = computed(() => {
  const slideNames = Object.keys(slots).filter((key) => key.startsWith('slide-'))
  console.log('slideNames', slideNames)
  return slideNames.map((name) => slots[name])
})
</script>

<template>
  <CCard class="mb-1 rounded-3shadow dashboard-card my-3">
    <CRow class="g-0">
      <CCol :xs="12" :sm="12" :md="12">
        <CCardBody>
          <CCard @click="go_to" @mouseover="hover = true" @mouseleave="hover = false" :class="{ 'hover-card': hover }">
            <CRow>
              <CCardBody class="m-3">
                <CCardImage class="rounded-3 panel-image ms-3 float-image" :src="image" />
                <h5>
                  <slot name="title" />
                </h5>
                <!-- small display -->
                <div class="d-block linklike">
                    <p v-if="show_details">
                      <slot name="description_short" />
                    </p>
                    <p v-else class="clamped">
                      <slot name="description_short" />
                    </p>
                </div>
              </CCardBody>
            </CRow>
          </CCard>
          <div class="d-none d-sm-block">
            <CCardText @click="go_to"><slot name="details" /></CCardText>
            <CButton color="primary" class="btn-primary-cs btn-primary-sm" v-if="!props.disableButton">
              <slot name="call-to-action">
                {{ t('dashboard.start-now') }}
              </slot>
            </CButton>
          </div>
        </CCardBody>
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <slot name="slides"></slot>
      </CCol>
    </CRow>
  </CCard>
</template>

<style scoped lang="scss">
.show-details {
  cursor: pointer;
  float: right;
}
.panel-image {
  max-height: 145px;
  width: auto;
  height: auto;
  /* float: right;*/
  /* margin-left: 1rem; */
}
.dashboard-card {
  padding: 5px 10px;
  .image-div {
    display: inline-block;
  }
  .image-div img {
  }
  .card-body{
      text-align: left;
      padding: 5px 10px;
  }
  .card-title{
      font-size: 1.2rem;
      color: var(--text-color);
      font-weight: 600;
  }
  .card-text{
      color: var(--secondary-text-color);
  }
}
.clamped {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.hover-card {
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ddf;
}
.float-image {
  float: right;
}
</style>
