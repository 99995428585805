<script setup>
  import { ref } from 'vue'
  const emit = defineEmits(['go_to'])
  const show_details = ref(false)
  function go_to() {
    console.log("click")
    emit('go_to')
  }
  function toggle_details() {
    console.log("toggle_details")
    show_details.value = !show_details.value
  }
</script>
<template>
  <CCard class="mb-5 card-cs shadow">
    <CRow class="g-0">
      <CCol :xs="4" @click="go_to()" class="linklike">
        <slot name="image" />
      </CCol>
      <CCol :xs="8">
        <i v-if="show_details" class="bi bi-chevron-up details-link" @click="toggle_details"></i>
        <i v-if="!show_details" class="bi bi-chevron-down details-link" @click="toggle_details()"></i>
        <h2 class="mb-2 card-title linklike" @click="go_to()">
          <slot name="title" />
        </h2>
        <div @click="go_to()" class="d-block d-sm-none linklike">
          <slot v-if="show_details" name="details" />
          <div v-else class="clamped leftgap" >
            <slot name="details" />
          </div>

        </div>
        <div @click="go_to()" class="d-none d-sm-block linklike leftgap">
          <slot name="details" />
        </div>
      </CCol>
    </CRow>
  </CCard>
</template>
<style lang="scss">
  .details-link {
    cursor: pointer;
    float: right;
  }
  .linklike {
    cursor: pointer;
  }
  .clamped {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-left: 10px;
  }
  .leftgap {
    margin-left: 15px;
    margin-right: 10px;
    text-align: left;
  }
</style>
