/* designed to be used as a singleton, and injected into all components
so we have a single point of entry to audio, which avoids multiple audio
playing over top of each other, and allows us to manage the icon states
across different components.  also, by not having actual audio elements
in the html this avoids pre-loading the TTS urls, and saves money. 
The AudioManager maintains a LIFO queue of audio objects keyed by the URL.
We can preload URLs so they can be played instantly. If we call play(url)
and we already have an Audio object associated with that URL, we should call
play on it. If we call play(url) and it hasn't been loaded, we should first
call preload(url). */
export class AudioManager {
  constructor() {
    this.audioStack = []
    this.playback_speed = 1
    this.playing = false
    this.onended = () => {}  // default to a no-op
  }

  // should only be called from the settings modal
  setPlaybackSpeed(speed) {
    this.playback_speed = speed
  }

  preload(url) {
    // Check if the URL is already loaded
    console.log("preloading url", url)
    const index = this.audioStack.findIndex(obj => obj.url === url)
    if (index !== -1) {
      console.log(`URL ${url} is already preloaded`)
      // Move the existing audio object to the head of the queue
      const [audioObj] = this.audioStack.splice(index, 1)
      this.audioStack.push(audioObj)
      return
    }

    const audio = new Audio(url)
    audio.preload = 'auto'
    this.audioStack.push({ url, audio })
  }

  play(url) {
    console.log("play url", url)
    this.playing = false
    // this.onended_wrapper()

    let audioObj = this.audioStack.find(obj => obj.url === url)
    if (!audioObj) {
      this.preload(url)
      audioObj = this.audioStack.find(obj => obj.url === url)
    }
    else {
      console.log("found audio object in stack")
      // Move the existing audio object to the head of the queue
      const index = this.audioStack.findIndex(obj => obj.url === url)
      this.audioStack.splice(index, 1)
      this.audioStack.push(audioObj)
    }
    console.log("ultimately we got this audio object", audioObj)

    this.audio = audioObj.audio
    // setting the audio source resets the playback rate
    console.log("setting playback rate to", this.playback_speed)
    this.audio.playbackRate = this.playback_speed
    return this.play_with_retry(url)
  }

  /* if the audio fails to play, try again with exponential backoff and a hard limit of 5 attempts */
  async play_with_retry(url) {
    console.log("entering play_with_retry", url)
    let attempts = 0
    while (attempts < 5) {
      try {
        this.audio.playbackRate = this.playback_speed
        this.audio.onended = this.onended_wrapper.bind(this)
        await this.audio.play()
        this.playing = true
        console.log('play succeeded after', attempts, 'attempts')
        return
      } catch (err) {
        attempts++
        console.error('trapped play error', err, 'retrying', attempts)
        await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, attempts)))
      }
    }
  }

  onended_wrapper() {
    this.playing = false
    this.onended()
  }

  pause() {
    if (this.audio) {
      this.audio.pause()
    }
    else {
      console.error("no audio object to pause")
    }
    this.onended_wrapper()
  }

  /* basically so we can reset the pause icon to be a play icon */
  setOnEnded(callback) {
    this.onended = callback
    if (this.audio) {
      this.audio.onended = this.onended_wrapper.bind(this)
    }
    else {
      console.error("no audio object to set onended")
    }
  }
}

