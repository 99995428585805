<template>
  <ion-button :style="buttonStyle()" class="icon-top-button" :router-link="routerLink" :disabled="disabled">
    <div>
    <slot name="body">
      <ion-icon :color="color" :icon="icon"></ion-icon>
      <ion-label :color="color">{{ label }}</ion-label>
    </slot>
    </div>
  </ion-button>
</template>

<script setup>
import { IonButton, IonIcon, IonLabel } from '@ionic/vue';
import { isApp } from '@/helpers/utils.js'

const props = defineProps({
  icon: String,
  label: String,
  routerLink: String,
  disabled: Boolean,
  color: String,
});

function buttonStyle() {
  if (isApp) {
    return "margin-bottom: -6px;"
  }
  return "margin-bottom: 0px;"
}

</script>

<style scoped>
ion-button div {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #424242;
}
ion-button div ion-icon {
  font-size: 26px;
  margin-bottom: 4px;
}
ion-button div ion-label {
  font-size: 12px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0.03em !important;
}
</style>
