<script setup>
  import { ref, onMounted } from 'vue'
  import { fetchWrapper } from '@/helpers/fetch-wrapper'
  import { useProfileStore } from '@/stores/profile.store'
  import CurriculumTopicSlide from '@/components/CurriculumTopicSlide.vue'
  const profileStore = useProfileStore()
  import { useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
  const router = useRouter()
  const categories = ref([])
  const suggestedUnits = ref([])
  const language = ref('')
  const invalidLanguage = ref(false)
  const isLoading = ref(true)
  async function load_categories() {
    try {
      const params = {
        native_language: profileStore.profile.native_language,
        learning_language: profileStore.profile.learning_language,
      }
      const query = new URLSearchParams(params)
      const response = await fetchWrapper.get(`/curriculum/categories/?${query}`)
      categories.value = response
      invalidLanguage.value = false
    } catch (error) {
      language.value = 'Error'
      invalidLanguage.value = true
      categories.value = []
    }
  }

  async function load_suggested_units() {
    try {
      const params = {
        language: profileStore.profile.learning_language,
      }
      const query = new URLSearchParams(params)
      const response = await fetchWrapper.get(`/curriculum/unit-recommendations/?${query}`)
      suggestedUnits.value = response
    } catch (error) {
      console.error('Error loading suggested units:', error)
      suggestedUnits.value = []
    }
  }

  onMounted(async () => {
    await profileStore.fetchProfile()
    await load_categories()
    await load_suggested_units()
    isLoading.value = false
  })

  async function change_learning_language() {
    await load_categories()
    await load_suggested_units()
  }
</script>
<template>
  <nice-header />
  <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
  <div v-if="!isLoading">
    <section class="my-5">
      <div class="container tutorial">
        <div class="bg-white border-0 rounded border-light p-1 p-sm-2 p-md-5 w-100 fmxw-500">
          <div class="text-center mb-4 mt-md-0 col-12">
            <h1 v-if="!invalidLanguage" class="mb-0 h1-title pb-3">{{ language }} {{ t('cards.course') }}</h1>
            <h1 v-else class="mb-0 h1-title pb-3">{{ t('curriculum.explore') }}</h1>
            <hr class="my-1" />
            <div class="row">
              <div class="col-md-6 text-left">
                <current-language @changeLanguage="change_learning_language" />
              </div>
            </div>
            <br />
            <h2>{{ t('curriculum.suggestions') }}</h2>
            <hr />
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center "  v-for="unit in suggestedUnits" :key="unit.id">
                <CurriculumTopicSlide :topic="unit" :to="{ name: 'CurriculumUnitView', params: { unitID: unit.id } }" />
              </div>
            </div>
            <br />
            <h2>{{ t('curriculum.categories') }}</h2>
            <hr />
            <div class="row">
              <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 d-flex justify-content-center" v-for="category in categories" :key="category.id">
                <CurriculumTopicSlide :topic="category" :to="{ name: 'CurriculumCategoryView', params: { categoryID: category.id } }" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <nice-footer />
</template>
<style scoped>
a {
  text-decoration: none;
  color: var(--primary-color);
}
.card {
  width: 115px;
  height: 100px;
}
</style>

