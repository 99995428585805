<script setup>
import { ref } from 'vue'
import { fetchWrapper } from '@/helpers/fetch-wrapper'
import { useI18n } from 'vue-i18n'
import { IonContent, IonPage } from '@ionic/vue'
const { t } = useI18n()
const email = ref('')
const error = ref('')
const success = ref('')
const isLoading = ref(false)
import { isApp } from '@/helpers/utils.js'

async function unsubscribe() {
  isLoading.value = true
  console.log('before hit')
  const response = await fetchWrapper.get(`/unsubscribe/?email=${email.value}`).catch( function (error) { 
    console.log('failed' + error);
    error.value = error
  })
  isLoading.value = false
  if (response.error) {
    error.value = response.error
  } else {
    success.value = "Your email has been unsubscribed"
  }

}

</script>
<template>
  <ion-page>
    <ion-content id="forgotpasswordview">
      <site-header v-if="isApp === false"/>
        <section class="loginForm my-5 py-4">
          <div class="container"></div>
            <div class="col-lg-10 offset-lg-1 col-xs-12 col-sm-12" v-if="!isLoading">
              <div class="bg-white shadow border-0 rounded border-light p-1 p-sm-2 p-md-5 w-100 fmxw-500">
                <div class="text-center text-md-center mb-4 mt-md-0 col-12">
                  <h1 class="mb-0 h1-title">{{ t('profile.unsubscribe') }}</h1>
                  <hr />
                </div>
                <div class="d-flex justify-content-center gap-2"></div>
                <div v-if="error" class="row">
                  <div class="col-md-6 offset-md-3 col-xs-12 col-sm-12">
                    <div class="alert alert-danger">{{ error }}</div>
                  </div>
                </div>
                <div v-if="success" class="row">
                  <div class="col-md-6 offset-md-3 col-xs-12 col-sm-12">
                    <div class="alert alert-success">{{ success }}</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 offset-md-3 col-xs-12 col-sm-12">
                    <div class="mb-3">
                      <label for="email">{{ t('profile.your-email') }}</label>
                      <input type="email" class="form-control" id="email" v-model="email" />
                    </div>
                    <div class="mb-3">
                      <button class="btn btn-primary" @click="unsubscribe">{{ t('profile.unsubscribe') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
      <div class="cityBgBottom"></div>
      <site-footer v-if="isApp === false"/>
    </ion-content>
  </ion-page>
</template>
